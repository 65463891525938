/* eslint-disable no-invalid-this*/
import * as tslib_1 from "tslib";
import { BaseService } from './base.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Global } from './../../../global.component';
import { EncryptService } from './encrypt.service';
import { Router } from '@angular/router';
import { Subject } from 'rxjs/Subject';
var CommonService = /** @class */ (function (_super) {
    tslib_1.__extends(CommonService, _super);
    /**
     *Creates an instance of CommonService.
     * @param {HttpClient} http
     * @param {EncryptService} encryptservice
     * @memberof CommonService
     */
    function CommonService(http, encryptservice, router) {
        // initializing all the header parameters
        var _this = _super.call(this, http) || this;
        _this.http = http;
        _this.encryptservice = encryptservice;
        _this.router = router;
        _this.headers = {};
        _this.options = {};
        _this.auditRecordheaders = {};
        _this.auditRecordoptions = {};
        _this.notify = new Subject();
        /**
         * Observable string streams
         */
        _this.notifyObservable$ = _this.notify.asObservable();
        _this.headers['Content-Type'] = 'application/json';
        _this.auditRecordheaders['Content-Type'] = 'application/json';
        _this.headers['Authorization'] = sessionStorage.getItem('token');
        if (sessionStorage.getItem('userType') == 'Administrator on Demand') {
            _this.headers['orgId'] = sessionStorage.getItem('orgId');
        }
        var httpHeaders = new HttpHeaders(_this.headers);
        _this.options['headers'] = httpHeaders;
        _this.options['observe'] = 'response';
        var httpAuditHeaders = new HttpHeaders(_this.auditRecordheaders);
        _this.auditRecordoptions['headers'] = httpAuditHeaders;
        _this.auditRecordoptions['observe'] = 'response';
        _this.auditRecordoptions['module'] = 'auditlog';
        _this.encryptService = encryptservice;
        return _this;
    }
    /**
     *
     *
     * @param {*} formData
     * @return {any} audit record success msg
     * @memberof CommonService
     */
    CommonService.prototype.insertAuditRecords = function (formData) {
        var encryptedPayload = this.encryptService.encryptAuditPayload(JSON.stringify(formData));
        return this.post(Global.getAPIURI('addAuditRecord'), encryptedPayload, this.auditRecordoptions);
    };
    /**
     *
     *
     * @param {*} permissionArray
     * @return {object} modulePermission
     * @memberof CommonService
     */
    CommonService.prototype.getModulePermission = function (permissionArray) {
        var modulePermission = { 'view': false, 'add': false, 'edit': false, 'delete': false };
        if (permissionArray.includes('V'))
            modulePermission['view'] = true;
        if (permissionArray.includes('E'))
            modulePermission['edit'] = true;
        if (permissionArray.includes('A'))
            modulePermission['add'] = true;
        if (permissionArray.includes('D'))
            modulePermission['delete'] = true;
        return modulePermission;
    };
    /**
     * This common service will be used to sort the Items alphabetically
     *
     * @param {*} arrObject
     * @param {*} fieldName
     * @param {*} order
     * @return {any}
     * @memberof CommonService
     */
    CommonService.prototype.sortColumn = function (arrObject, fieldName, order) {
        if (order === void 0) { order = 'asc'; }
        // to return empty on empty array
        if (arrObject.length == 0)
            return [];
        var sortedObject = arrObject.sort(function (t1, t2) {
            var name1 = t1[fieldName];
            var name2 = t2[fieldName];
            // to sort all the strings
            if (isNaN(name1) == true && isNaN(name2) == true) {
                name1 = name1.toLowerCase();
                name2 = name2.toLowerCase();
                if (name1 > name2 && order == 'asc') {
                    return 1;
                }
                else {
                    return -1;
                }
                return 0;
            }
            else {
                // to sort all the numbers
                if (order == 'asc')
                    return name1 - name2;
                else
                    return name2 - name1;
            }
        });
        return sortedObject;
    };
    /**
     * This method will help us the find the level 3
     * navigations that are used along with selected options
     * @param {string} parentModule
     * @param {string} activeModule
     * @return {*}
     * @memberof CommonService
     */
    CommonService.prototype.getSubNavigation = function (parentModule, activeModule) {
        var result = [];
        var navigationModules = JSON.parse(sessionStorage.getItem('mainNavigation'));
        // to find the parent module to display their sub menus
        for (var index in navigationModules) {
            if (navigationModules[index]['children'].length == 0)
                continue;
            for (var index2 in navigationModules[index]['children']) {
                var module_1 = navigationModules[index]['children'][index2];
                if (module_1['moduleName'] == parentModule) {
                    result = module_1['children']; // return level 3 menu item
                }
            }
        }
        // to find the active module
        for (var index in result) {
            if (result[index]['moduleName'] == activeModule) {
                result[index]['selectedCSS'] = 'color active';
                result[index]['selected'] = true;
            }
            else {
                result[index]['selectedCSS'] = 'color';
                result[index]['selected'] = false;
            }
        }
        return result;
    };
    /**
     * This module will help us to identify if the feature
     * is released or not based on the navigation menu available
     *
     * @param {string} moduleName
     * @return {boolean}
     * @memberof CommonService
     */
    CommonService.prototype.isFeatureReleased = function (moduleName) {
        var navigationModules = JSON.parse(sessionStorage.getItem('mainNavigation'));
        var isFeatureReleased = function (module) {
            moduleName = this.toString();
            if (module['moduleName'] === moduleName)
                return true;
            else if (typeof (module['children']) !== 'undefined' && module['children'].length > 0) {
                return module['children'].some(isFeatureReleased, moduleName);
            }
            else
                return false;
        };
        // .some() iterates array till it gets a true, is uses a callback function to achieve this
        return navigationModules.some(isFeatureReleased, moduleName);
    };
    /**
     * To check if the access is available based on the
     * Featured released
     *
     * @param {string} url
     * @return {boolean}
     * @memberof CommonService
     */
    CommonService.prototype.isAccessGranted = function (url) {
        url = url.replace('/', '');
        var releasedModules = JSON.parse(sessionStorage.getItem('mainNavigation'));
        var isAccessGranted = function (module) {
            url = this.toString();
            if (url.includes(module['url']))
                return true;
            else if (typeof (module['children']) !== 'undefined' && module['children'].length > 0) {
                return module['children'].some(isAccessGranted, url);
            }
            else
                return false;
        };
        // .some() iterates array till it gets a true, is uses a callback function to achieve this
        return releasedModules.some(isAccessGranted, url);
    };
    /**
     * @param {*} time
     * @return {any} timezone
     * @memberof CommonService
     */
    CommonService.prototype.getUTCTimezone = function (time) {
        var utcTime = "UTC" + time;
        return utcTime;
    };
    /**
     * This method will convert time format HH:MM to actual minutes
     *
     * @param {*} strTime
     * @return {number}
     * @memberof CommonService
     */
    CommonService.prototype.stringToMinutes = function (strTime) {
        var timeArray = strTime.split(":");
        var totalMinutes = 0;
        if (timeArray.length == 2)
            totalMinutes = parseInt(timeArray[0]) * 60 + parseInt(timeArray[1]);
        else
            totalMinutes = parseInt(timeArray[0]) * 60;
        return totalMinutes;
    };
    /**
    * This method will convert the actual minutes to Hours strings in HH:MM
    *
    * @param {*} minutes
    * @return {string}
    * @memberof CommonService
    */
    CommonService.prototype.minutesToHours = function (minutes) {
        var hoursArray = (minutes / 60).toString().split('.');
        var hours = (hoursArray[0].length == 1) ? "0" + hoursArray[0] : hoursArray[0];
        var mins = (minutes % 60);
        mins = (mins < 0) ? -(mins) : mins;
        mins = mins.toString();
        mins = (mins.length == 1) ? "0" + mins : mins;
        return hours + ":" + mins;
    };
    /**
      * This method will help us to filter out the data based on the
      * requirement, the filtervalue is case insensitive whereas
      * the filterField is case sensitive
      *
      * @param {object[]} arrObj
      * @param {string} filterField
      * @param {string} filterValue
      * @return {object[]}
      * @memberof CommonService
      */
    CommonService.prototype.filterDataRecords = function (arrObj, filterField, filterValue) {
        var filteredRecords = [];
        if (typeof (arrObj) !== 'object' || arrObj == null)
            return filteredRecords;
        filteredRecords = arrObj.filter(function (val) {
            if (typeof (val[filterField]) !== 'undefined' && val[filterField].toLowerCase() == filterValue.toLowerCase())
                return true;
        });
        return filteredRecords;
    };
    /**
     * This will decide whether features or released or not for customer administrator
     * If feature is found, it returns true
     * @param {object[]} menu
     * @param {string} moduleName
     * @return {boolean}
     * @memberof CommonService
     */
    CommonService.prototype.checkReleasedAdminFeatures = function (menu, moduleName) {
        var level1 = menu.find(function (item) {
            if (item['moduleName'] === moduleName)
                return true;
            if (item['children'].length > 0) {
                var level2 = item['children'].find(function (subitem) {
                    if (subitem.moduleName === moduleName)
                        return true;
                    if (subitem.children.length > 0) {
                        var level3 = subitem.children.find(function (innerItem) {
                            if (innerItem.moduleName === moduleName)
                                return true;
                        });
                        if (typeof (level3) !== 'undefined')
                            return true;
                    }
                });
                if (typeof (level2) !== 'undefined')
                    return true;
            }
        });
        return (typeof (level1) === 'undefined') ? false : true;
    };
    /**
   * Method to validate all the callflowlists
   * before deleting them
     *
     * @param {string} callflowListID
     * @return {Observable<any>}
     * @memberof CommonService
     */
    CommonService.prototype.validateCallFlowListBeforeDelete = function (callflowListID) {
        return this.get(Global.getAPIURI('validateCallflowListBeforeDelete') + "?ref=" + callflowListID, this.options);
    };
    /**
   *Method to redirect the callflow page
   * after  clicking on  hyperlink
   * @param {any} callflowvalue
   * @param {any} modalReference
   *  @memberof commomservice
   */
    CommonService.prototype.redirectingCallFlows = function (callflowvalue, modalReference) {
        if (modalReference != undefined) {
            modalReference.close();
        }
        var morefields = JSON.parse(sessionStorage.getItem('moreFields'));
        var permissions = JSON.parse(sessionStorage.getItem('userPermission'));
        if ((permissions['Call Flow Editor Beta'] && permissions['Call Flow Editor Beta'].indexOf('V') != -1)
            && (morefields['betaMode'] == undefined || morefields['betaMode'] == true)) {
            this.router.navigate(["/react-callflow-editor/" + callflowvalue['callFlowId'] + "/version/" + callflowvalue['versionId']]);
        }
        else {
            this.router.navigate(["/call-flow-editor/" + callflowvalue['callFlowId'] + "/version/" + callflowvalue['versionId']]);
        }
    };
    CommonService.prototype.notifyOther = function (data) {
        if (data) {
            this.notify.next(data);
        }
    };
    return CommonService;
}(BaseService));
export { CommonService };
