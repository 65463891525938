import * as tslib_1 from "tslib";
import { Constants } from './constants';
import { DatePipe } from '@angular/common';
import { PipeTransform } from '@angular/core';
var DateTimeFormatPipe = /** @class */ (function (_super) {
    tslib_1.__extends(DateTimeFormatPipe, _super);
    function DateTimeFormatPipe() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    DateTimeFormatPipe.prototype.transform = function (value, args) {
        return _super.prototype.transform.call(this, value, Constants.DATE_TIME_FMT);
    };
    return DateTimeFormatPipe;
}(DatePipe));
export { DateTimeFormatPipe };
