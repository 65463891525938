import * as tslib_1 from "tslib";
import { Constants } from './constants';
import { PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
var DateWithoutYearFormatPipe = /** @class */ (function (_super) {
    tslib_1.__extends(DateWithoutYearFormatPipe, _super);
    function DateWithoutYearFormatPipe() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    DateWithoutYearFormatPipe.prototype.transform = function (value, args) {
        return _super.prototype.transform.call(this, value, (args && args.length > 0) ? Constants.MONTH_FMT : Constants.DATE_FMT_WITHOUT_YEAR);
    };
    return DateWithoutYearFormatPipe;
}(DatePipe));
export { DateWithoutYearFormatPipe };
