import * as tslib_1 from "tslib";
import { BaseService } from './../shared';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Global } from '../../global.component';
import { EncryptService } from './../shared/services';
var LoginService = /** @class */ (function (_super) {
    tslib_1.__extends(LoginService, _super);
    function LoginService(http, encryptService) {
        var _this = _super.call(this, http) || this;
        _this.http = http;
        _this.encryptService = encryptService;
        _this.headers = {};
        _this.options = {};
        return _this;
    }
    LoginService.prototype.setHeaders = function (idToken) {
        this.headers['Content-Type'] = 'application/json';
        this.headers['Authorization'] = idToken;
        var httpHeaders = new HttpHeaders(this.headers);
        return this.options = { headers: httpHeaders };
    };
    LoginService.prototype.checkAPIGateway = function (formData) {
        this.options = this.setHeaders(formData['idToken']);
        return this.get(Global.getAPIURI('checkAPIGateway'), this.options);
    };
    LoginService.prototype.logINWithMFA = function (formData) {
        this.headers['Content-Type'] = 'application/json';
        var httpHeaders = new HttpHeaders(this.headers);
        this.options = { headers: httpHeaders, observe: "response", responseType: "json" };
        var payload = formData;
        payload["operation"] = "logIN";
        return this.post(Global.getAPIURI('logIN'), this.encryptService.encryption(JSON.stringify(payload)), this.options);
    };
    LoginService.prototype.getProductName = function () {
        return this.get(Global.getAPIURI('getProductName'));
    };
    LoginService.prototype.getCostCentre = function (formData) {
        this.options = this.setHeaders(formData['idToken']);
        delete (formData['idToken']);
        var payload = formData;
        payload["operation"] = "listCostCentre";
        return this.post(Global.getAPIURI('organisationList'), payload, this.options);
    };
    LoginService.prototype.getOrganization = function (formData) {
        this.options = this.setHeaders(formData['idToken']);
        var payload = {
            "operation": "listOrganization"
        };
        return this.post(Global.getAPIURI('organisationList'), payload, this.options);
    };
    LoginService.prototype.role = function (formData, operation) {
        this.options = this.setHeaders(formData['idToken']);
        delete (formData['idToken']);
        var payload = formData;
        payload["operation"] = operation;
        return this.post(Global.getAPIURI('getroleList'), payload, this.options);
    };
    LoginService.prototype.getNavigation = function (idToken) {
        this.options = this.setHeaders(idToken);
        var payload = {
            "service": "mainNavigation"
        };
        return this.post(Global.getAPIURI('uiConfig'), payload, this.options);
    };
    /*
    @AUTHOR: Sovan Dey
    UCP-6932
    return: will a json of username and password
    accept: will take base64decoded text
    */
    LoginService.prototype.getTempPassword = function (token) {
        var payload = {
            "approvalToken": token
        };
        return this.post(Global.getAPIURI('getTempPassword'), payload, this.options);
    };
    return LoginService;
}(BaseService));
export { LoginService };
