import * as tslib_1 from "tslib";
import { Constants } from './constants';
import { PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
var TimeWithSecondsFormatPipe = /** @class */ (function (_super) {
    tslib_1.__extends(TimeWithSecondsFormatPipe, _super);
    function TimeWithSecondsFormatPipe() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    TimeWithSecondsFormatPipe.prototype.transform = function (value, args) {
        return _super.prototype.transform.call(this, value, Constants.TIMEWITHSECONDS_FMT);
    };
    return TimeWithSecondsFormatPipe;
}(DatePipe));
export { TimeWithSecondsFormatPipe };
